
import { Vue, Component, Prop, Emit } from 'nuxt-property-decorator'
import IconWithTip from '~/components/UI/IconWithTip.vue'

@Component({
  components: { IconWithTip }
  })
export default class SearchEmailField extends Vue {
  @Prop() value: string | null
  @Prop({ default: false }) error?: boolean
  @Prop({ default: false }) hideLabel?: boolean

  @Emit()
  input (_val: string) {

  }

  handleInput (e: Event) {
    this.input((e.target as HTMLInputElement).value)
  }
}
