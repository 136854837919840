
import { Component, Vue } from 'nuxt-property-decorator'

@Component({
  name: 'StatInfo'
  })

export default class extends Vue {
  isVisibleStatInfo: string | null = 'invisible';

  get accrualsFoundCount () {
    // 25 000 000 + (unix timestamp/5000)
    return 25_000_000 + this.timestampDivided(5000)
  }

  get savedAmount () {
    // 400 000 000 + (unix timestamp/50)
    return 400_000_000 + this.timestampDivided(50)
  }

  timestampDivided (divider: number) {
    const unixTimestamp: number = Math.floor(Date.now() / 1000)
    return Math.floor(unixTimestamp / divider)
  }

  closeWindow () {
    localStorage.setItem('isVisibleStatInfo', 'invisible')
    this.isVisibleStatInfo = 'invisible'
  }

  beforeMount () {
    const isVisible = localStorage.getItem('isVisibleStatInfo')
    if (isVisible) {
      this.isVisibleStatInfo = isVisible
    } else {
      this.isVisibleStatInfo = 'visible'
    }
  }
}
