import { render, staticRenderFns } from "./PushNotify.vue?vue&type=template&id=72eff524&scoped=true"
import script from "./PushNotify.vue?vue&type=script&lang=ts"
export * from "./PushNotify.vue?vue&type=script&lang=ts"
import style0 from "./PushNotify.vue?vue&type=style&index=0&id=72eff524&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72eff524",
  null
  
)

export default component.exports